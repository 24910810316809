import {bool, func, string} from 'prop-types'
import React from 'react'

import useStyles from './styles'

const Popper = ({
  cta1,
  cta2,
  label,
  link,
  setShowPopper,
  showPopper,
  showLink,
  shouldOpenInNewTab,
}) => {
  const classes = useStyles()
  const displayCondition = showPopper ? 'flex' : 'none'
  const noSlashLink = link.replaceAll('//', '')

  return (
    <>
      <div style={{display: displayCondition}} className={classes.wrapper}>
        <div id="popper" className={classes.container}>
          <div className={classes.descriptionWrapper}>
            <p className={classes.description}>{label}</p>
            {showLink && <p className={classes.link}>{noSlashLink}</p>}
          </div>
          <div className={classes.actionWrapper}>
            <div className={classes.action}>
              <button
                type="button"
                onClick={setShowPopper}
                className={classes.actionElement}
              >
                {cta1}
              </button>
            </div>
            <div className={classes.action}>
              <a
                onClick={setShowPopper}
                target={shouldOpenInNewTab ? '_blank' : null}
                rel="noreferrer"
                href={link}
                className={classes.actionElement}
              >
                {cta2}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Popper.propTypes = {
  cta1: string,
  cta2: string,
  label: string,
  link: string,
  setShowPopper: func,
  shouldOpenInNewTab: bool,
  showLink: bool,
  showPopper: bool,
}
Popper.defaultProps = {
  cta1: '',
  cta2: '',
  label: '',
  link: '',
  setShowPopper: null,
  shouldOpenInNewTab: true,
  showLink: true,
  showPopper: false,
}

export default Popper
