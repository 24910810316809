import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({breakpoints, shadow, layout, palette, fontSizes, spacing}) => ({
    wrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 100,
      borderRadius: 4,
      [breakpoints.down('xs')]: {
        width: '100%',
      },
      background: 'rgba(50, 50, 50, 0.5)',
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      width: layout.popper.width,
      minHeight: layout.popper.height,
      backgroundColor: palette.background.default,
      zIndex: 100,
      boxShadow: shadow.large,
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.down('xs')]: {
        width: '100%',
      },
      padding: spacing(4),
      textAlign: 'center',
    },
    close: {
      width: ' fit-content',
      position: 'absolute',
      right: 10,
      top: 5,
      fontSize: 20,
    },
    descriptionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      flex: 3,
      marginBottom: 30,
    },
    description: {
      fontFamily: 'CamptonLight',
      fontSize: fontSizes.description,
    },
    link: {
      margin: '0 auto',
      fontFamily: 'CamptonLight',
      fontSize: fontSizes.description,
    },
    actionWrapper: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    action: {
      width: '40%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'CamptonLight',
      '& a': {
        background: palette.secondary.main,
        border: `2px solid ${palette.secondary.main}`,
        '&:hover': {
          color: `${palette.secondary.main} !important`,
          background: 'none',
          border: `2px solid ${palette.secondary.main}`,
        },
      },
      '& button': {
        background: palette.primary.main,
        border: `2px solid ${palette.primary.main}`,
        '&:hover': {
          color: palette.primary.main,
          background: 'none',
          border: `2px solid ${palette.primary.main}`,
        },
      },
    },
    actionElement: {
      height: '100%',
      color: 'white',
      borderRadius: 6,
      padding: spacing(0.25, 0.75),
      transition: 'all 0.25s',
    },
  }),
)

export default useStyles
