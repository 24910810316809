import * as R from 'ramda'
import {getServices} from 'helpers/services'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'

import defaultTheme from 'config/theme'
import FooterKls from 'components/Layout/FooterKls'
import HeaderKls from 'components/Layout/HeaderKls'
import palette2 from 'config/palette2'
import Popper from 'components/UI/Popper'
import ScrollTop from 'components/UI/ScrollTopButton'
import useAosInit from 'hooks/useAosInit'
import useIsMobile from 'hooks/useIsMobile'
import useMountComponent from 'hooks/useMountComponent'

import useStyles from './styles'

const LayoutKls = props => {
  const isMobile = useIsMobile()
  const {children, data, is404, layoutData, pageContext} = props
  const {nodeLocale} = pageContext
  const countryData = R.path(['contentfulCountry'], data)

  const services = R.pathOr(null, ['contentfulCountry', 'services'], data)

  const topLevelServices =
    services && R.pathOr(null, ['topLevelServices'], services)

  const countryServices =
    topLevelServices && getServices(data, topLevelServices)
  const classes = useStyles()

  const [showLayout, setShowLayout] = useState(false)
  const [showPopper, setShowPopper] = useState(false)
  const [popperLink, setPopperLink] = useState('')

  const isLayoutMounted = useMountComponent()
  const showNav = process.env.GATSBY_IN_SITE_MAINTENANCE === 'false'

  const localizedHeader = R.filter(
    el => el.node.node_locale === nodeLocale,
    layoutData.allContentfulHeader.edges,
  )

  const {redirectionPopperLabel, redirectionPopperCta1, redirectionPopperCta2} =
    !is404 && localizedHeader && R.pathOr(null, [0, 'node'], localizedHeader)

  const handleLayoutDisplay = bool =>
    bool
      ? {visibility: 'visible', marginTop: isMobile ? 25 : 100}
      : {visibility: 'hidden'}

  useEffect(() => {
    setShowLayout(isLayoutMounted)
  }, [isLayoutMounted])

  useAosInit(showLayout)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <ThemeProvider theme={palette2}>
          <Popper
            showPopper={showPopper}
            setShowPopper={() => setShowPopper(!showPopper)}
            link={popperLink}
            label={redirectionPopperLabel}
            cta1={redirectionPopperCta1}
            cta2={redirectionPopperCta2}
          />
          <div
            style={handleLayoutDisplay(showLayout)}
            className={classes.wrapper}
          >
            {showNav && !is404 && (
              <HeaderKls
                pageContext={pageContext}
                countryData={countryData}
                data={layoutData}
                pageData={data}
                showPopper={showPopper}
                setShowPopper={setShowPopper}
                setPopperLink={setPopperLink}
                countryServices={countryServices}
              />
            )}
            {children}
            {showNav && !is404 && (
              <FooterKls
                pageContext={pageContext}
                countryData={countryData}
                data={layoutData}
              />
            )}
            <ScrollTop />
          </div>
        </ThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default LayoutKls
