import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing, breakpoints}) => ({
  scrollTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    padding: spacing(1.5, 2),
    boxSizing: 'border-box',
    textTransform: 'none',
    fontFamily: 'CamptonSemiBold',
    fontSize: '1.12em',
    borderRadius: '50%',
    height: 49,
    width: 49,
    position: 'fixed',
    bottom: 15,
    right: 18,
    zIndex: 99,
    border: 'none',
    outline: 'none',
    backgroundColor: palette.primary.main,
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${palette.primary.main}!important`,
      color: `${palette.text.light}!important`,
      borderColor: 'transparent!important',
    },
    [breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
  },
}))

export default useStyles
