import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({breakpoints, layout, palette, transition, shadow, spacing}) => ({
    navigationItem: {
      display: 'inline-flex',
      alignItems: 'center',
      margin: '0 .7em',
      position: 'relative',
      height: 45,

      '&::before': {
        content: '""',
        position: 'absolute',
        height: 4,
        width: '100%',
        bottom: '-4px',
        left: 0,
        backgroundColor: palette.secondary.main,
        borderRadius: spacing(0, 0.5),
        opacity: 0,
        transition: transition.animation(),
      },
      '&:hover': {
        '&::before': {
          opacity: 1,
          [breakpoints.down('sm')]: {
            opacity: 0,
          },
        },
      },
      '& a': {
        textDecoration: 'none',
        '-webkit-appearance': 'none !important',
        color: 'inherit',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      '& > a, & > div > a': {
        paddingTop: spacing(0.5),
        '&:hover': {
          color: palette.primary.main,
        },
      },
      [breakpoints.down('sm')]: {
        height: 'auto',
        marginRight: 0,
        marginTop: spacing(4),
        '& a': {marginLeft: spacing(1)},
        '&::before': {
          bottom: '-14px',
        },
      },
    },
    activeNavigationItem: {
      '& > a': {
        color: palette.primary.main,
      },
      '&::before': {
        opacity: 1,
        [breakpoints.down('sm')]: {
          opacity: 0,
        },
      },
    },
    modal: {
      position: 'absolute',
      top: `calc(${layout.header.modal.top}px - ${spacing(3.5)})`,
      left: layout.header.modal.left,
      background: 'white',
      padding: spacing(3.75),
      borderRadius: spacing(3, 0.5),
      boxShadow: shadow.large,
      display: 'flex',
      flexDirection: 'column',
      width: layout.header.modal.width.large,
      '&::before': {
        left: '40px',
        top: '-23px',
        width: '207px',
        height: '23px',
        content: "''",
        zIndex: '11',
        position: 'absolute',
      },
      '& a': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing(2),
        borderRadius: 5,
        padding: `${spacing(0.75)} ${spacing(1)}`,
        '&:hover': {
          fontFamily: 'CamptonMedium',
          background: palette.primary.hoverLight,
          color: palette.primary.main,
        },
      },
      [breakpoints.down('md')]: {
        left: layout.header.modal.leftTablet,
      },
    },
    submodal: {
      position: 'relative',
      '& a': {
        gap: 10,
      },
      [breakpoints.down('sm')]: {
        '& a': {
          all: 'inherit',
          minWidth: 200,
          width: 'min-content',
          padding: 10,
          borderRadius: 4,
          textAlign: 'end',
          marginTop: spacing(1.75),
          cursor: 'pointer',
          color: 'grey',
          fontFamily: 'CamptonLight',
          fontSize: 14,
          '&:hover': {
            background: palette.primary.hoverLight,
            color: palette.primary.main,
            fontFamily: 'CamptonMedium',
          },
        },
        '& a:first-of-type': {
          marginTop: spacing(2.5),
        },
      },
    },
    activeSubmodal: {
      '& > a': {
        background: palette.primary.hoverLight,
        color: palette.primary.main,
        fontFamily: 'CamptonMedium',
      },
    },
    collapsable: {
      paddingRight: '4px!important',
      cursor: 'pointer',
    },
    buttonArrow: {
      transform: 'rotate(225deg)',
    },
    arrowToggle: {
      cursor: 'pointer',
      color: palette.background.gray,
      '&:hover': {
        color: palette.primary.main,
      },
    },
    arrowRevert: {
      transform: 'rotate(0.5turn)',
    },
    modalIsMedium: {
      width: layout.header.modal.width.medium,
    },
  }),
)

export default useStyles
