import {bool, func, node, string} from 'prop-types'
import {Link as GatsbyLink} from 'gatsby'
import CtaArrowIcon from '@mui/icons-material/CallReceived'
import React from 'react'

import useIsBrowser from 'hooks/useIsBrowser'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const UiLink = ({
  label,
  path,
  isExternalLink,
  isOpenInNewWindow,
  isSameMenuItemForAllCountries,
  country,
  hasArrow,
  menuItemPerCountry,
  isSubmodal,
  togglePopper,
  setPopperLink,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const subStringPath = path.replace(/^(?:\.\.\/)+/, '')

  const differentCountry = !isSameMenuItemForAllCountries
    ? menuItemPerCountry &&
      menuItemPerCountry.filter(
        ({countries}) => `${countries[0].technicalName}` === country || null,
      )
    : null
  const differentCountryMenu = differentCountry && differentCountry[0]

  const linkIsNotSameForAllCountryAndIsInternal =
    differentCountryMenu && !differentCountryMenu.isExternalLink

  const linkIsNotSameForAllCountryAndIsExternal =
    differentCountryMenu && differentCountryMenu.isExternalLink

  const isPartners = rootPath => {
    if (useIsBrowser) {
      if (
        rootPath === '../playbook' &&
        window.location.hash !== '#/technology'
      ) {
        return false
      }
      if (
        rootPath === '../playbook#/technology' &&
        window.location.hash === '#/technology'
      ) {
        return true
      }

      return false
    }

    return false
  }

  const renderActiveClassName = () => {
    if (useIsBrowser) {
      const isLabelServices = label === 'Services'
      const isDeliveryModelsPath =
        window.location.pathname.includes('delivery-models')
      const hasNotAllPartnersHash = window.location.hash !== '#/technology'

      if (isLabelServices && isSubmodal && isDeliveryModelsPath) {
        return false
      }
      if (hasNotAllPartnersHash) {
        if (isSubmodal) {
          return classes.isSubmodalActive
        }

        return classes.isActive
      }

      return false
    }

    return false
  }

  const renderRoot = (
    rootPath,
    rootLabel,
    rootIsExternalLink,
    rootIsOpenInNewWindow,
  ) => {
    if (!rootIsExternalLink) {
      return (
        <GatsbyLink
          to={rootPath}
          className={isPartners(rootPath) ? classes.isActive : classes.link}
          activeClassName={renderActiveClassName()}
          partiallyActive
        >
          {rootLabel}
          {hasArrow && !isMobile && (
            <CtaArrowIcon className={classes.buttonArrow} />
          )}
        </GatsbyLink>
      )
    }

    return (
      <>
        <a
          className={classes.link}
          /* {...newWindow} */
          rel={rootIsOpenInNewWindow && 'noopener noreferrer'}
          target={rootIsOpenInNewWindow && '_blank'}
          href={rootIsOpenInNewWindow && `//${rootPath}`}
          onClick={() => {
            togglePopper()
            setPopperLink(`//${rootPath}`)
          }}
        >
          {rootLabel}
          {hasArrow && !isMobile && (
            <CtaArrowIcon className={classes.buttonArrow} />
          )}
        </a>
      </>
    )
  }

  const renderLink = () => {
    if (isSameMenuItemForAllCountries) {
      if (isExternalLink) {
        return renderRoot(
          subStringPath,
          label,
          isExternalLink,
          isOpenInNewWindow,
        )
      }

      return renderRoot(path, label, isExternalLink, isOpenInNewWindow)
    }

    if (linkIsNotSameForAllCountryAndIsInternal) {
      return renderRoot(
        path,
        differentCountryMenu.title,
        differentCountryMenu.isExternalLink,
        differentCountryMenu.isOpenInNewWindow,
      )
    }
    if (linkIsNotSameForAllCountryAndIsExternal) {
      return renderRoot(
        differentCountryMenu.link,
        differentCountryMenu.title,
        differentCountryMenu.isExternalLink,
        differentCountryMenu.isOpenInNewWindow,
      )
    }

    return false
  }

  return (
    <>
      {/* {showPopper && <Popper show={showPopper} link={path} />} */}
      {renderLink()}
    </>
  )
}

UiLink.propTypes = {
  country: string,
  hasArrow: bool,
  isExternalLink: bool,
  isOpenInNewWindow: bool,
  isSameMenuItemForAllCountries: bool,
  isSubmodal: bool,
  label: string,
  menuItemPerCountry: node,
  path: string,
  setPopperLink: func,
  togglePopper: func,
}
UiLink.defaultProps = {
  country: '',
  hasArrow: false,
  isExternalLink: false,
  isOpenInNewWindow: false,
  isSameMenuItemForAllCountries: false,
  isSubmodal: false,
  label: '',
  menuItemPerCountry: null,
  path: '',
  setPopperLink: null,
  togglePopper: null,
}

export default UiLink
