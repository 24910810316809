import * as R from 'ramda'
import {node, shape} from 'prop-types'
import classNames from 'classnames'
import Collapse from '@mui/material/Collapse'

import {dropDownLinkPropTypes} from 'helpers/propTypes'
import {dynamicRouting} from 'helpers/utils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Fade from '@mui/material/Fade'
import React, {useState} from 'react'
import UiLink from 'components/UI/LinkKls'
import useIsBrowser from 'hooks/useIsBrowser'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

function DropDownLink({
  country,
  menuTitle,
  menuLink,
  childMenu,
  prefix,
  slug,
  isItAnExternalLink,
  shouldThisLinkOpenInANewBrowserWindow,
  isSameMenuItemForAllCountries,
  menuItemPerCountry,
  hasShortPath,
  countryCode,
  navLocale,
  setShowPopper,
  togglePopper,
  setPopperLink,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const isMobile = useIsMobile()
  const toggle = () => {
    if (isMobile) {
      setIsOpen(!isOpen)
    }
  }

  const renderSubModal = () => (
    <div className={classes.submodal}>
      {R.map(
        ({
          id,
          title,
          link,
          isExternalLink: isItAnExternalLinkSub,
          shouldThisLinkOpenInANewBrowserWindow:
            shouldThisLinkOpenInANewBrowserWindowSub,
          isSameMenuItemForAllCountries: isSameMenuItemForAllCountriesSub,
        }) => {
          const getActiveSubmodalClass = () => {
            if (useIsBrowser) {
              return (
                link === slug ||
                (window.location.hash.length > 0 &&
                  link.indexOf(
                    decodeURIComponent(window.location.hash).replaceAll(
                      ' ',
                      '%20',
                    ),
                  ) !== -1)
              )
            }
          }

          return (
            <div
              key={id}
              className={classNames({
                [classes.activeSubmodal]: getActiveSubmodalClass(),
              })}
            >
              <UiLink
                country={country}
                path={dynamicRouting(
                  prefix,
                  link,
                  countryCode,
                  hasShortPath,
                  navLocale,
                )}
                label={title}
                isExternalLink={isItAnExternalLinkSub}
                isOpenInNewWindow={shouldThisLinkOpenInANewBrowserWindowSub}
                isSameMenuItemForAllCountries={isSameMenuItemForAllCountriesSub}
                hasArrow
                menuItemPerCountry={menuItemPerCountry}
                isSubmodal
                setShowPopper={setShowPopper}
                togglePopper={togglePopper}
                setPopperLink={setPopperLink}
              />
            </div>
          )
        },
        childMenu.menuItems,
      )}
    </div>
  )

  return (
    <>
      <li
        label="open sub menu"
        type="button"
        className={classNames(classes.navigationItem, {
          [classes.activeNavigationItem]: menuLink === slug,
        })}
        onMouseEnter={() => !isMobile && setIsOpen(true)}
        onMouseLeave={() => !isMobile && setIsOpen(false)}
      >
        {childMenu && R.length(childMenu.menuItems) > 0 && isMobile && (
          <ExpandMoreIcon
            className={classNames(classes.arrowToggle, {
              [classes.arrowRevert]: isOpen,
            })}
            onClick={() => toggle()}
          />
        )}

        <UiLink
          country={country}
          path={dynamicRouting(
            prefix,
            // TO DO : change it back when deploying to production
            menuLink === 'playbook#/technology' ? 'technology' : menuLink,
            countryCode,
            hasShortPath,
            navLocale,
          )}
          /*  key={id} */
          label={menuTitle}
          isExternalLink={isItAnExternalLink}
          isOpenInNewWindow={shouldThisLinkOpenInANewBrowserWindow}
          isSameMenuItemForAllCountries={isSameMenuItemForAllCountries}
          menuItemPerCountry={menuItemPerCountry}
        />
        {childMenu && R.length(childMenu.menuItems) > 0 && !isMobile && (
          <Fade in={isOpen}>
            <div className={classNames(classes.modal)}>{renderSubModal()}</div>
          </Fade>
        )}
      </li>
      {R.length(childMenu && childMenu.menuItems) > 0 && isMobile && (
        <div className={classes.collapsable}>
          <Collapse in={isOpen}>{renderSubModal()}</Collapse>
        </div>
      )}
    </>
  )
}

DropDownLink.propTypes = dropDownLinkPropTypes
DropDownLink.defaultPropTypes = shape({
  menuTitle: '',
  menuLink: '',
  slug: '',
  childMenu: node,
  prefix: '',
})

export default DropDownLink
