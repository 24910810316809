import {useEffect, useState} from 'react'

import useIsBrowser from 'hooks/useIsBrowser'

export default function useShowScrollTopButton() {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false)

  useEffect(
    () =>
      useIsBrowser &&
      window.addEventListener('scroll', () => {
        if (!showScrollTopButton && window.pageYOffset > 400) {
          setShowScrollTopButton(true)
        } else if (showScrollTopButton && window.pageYOffset <= 400) {
          setShowScrollTopButton(false)
        }
      }),
    [showScrollTopButton],
  )

  return [showScrollTopButton, setShowScrollTopButton]
}
