import * as R from 'ramda'
import {string} from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import {groupUrl} from 'helpers/utils'
import useIsBrowser from 'hooks/useIsBrowser'

import {countriesByLocalesKls} from '../../../../keyrus-life-science/static/countriesByLocalesKls'
import useStyles from './styles'

const SwitchLanguageSmall = ({currentCountry, allSitePage}) => {
  const classes = useStyles()

  const filteredCountries = R.filter(
    ({countryCode}) => countryCode !== currentCountry,
    countriesByLocalesKls,
  )

  const handleChange = (countryProp, localeProp) => {
    if (useIsBrowser) {
      const groupedUrl = groupUrl(window.location.pathname)
      const groupParent = groupedUrl.parent
      const groupSlug = groupedUrl.slug
      const parents = ['/services', '/playbook', '/insights']

      const parentSlug = parents.includes(groupParent)
        ? `${groupParent.substring(1)}/${groupSlug}`
        : groupSlug

      const slicedLocale = R.slice(0, 2, localeProp)
      const prefix = `${countryProp}/${slicedLocale}`

      const pathToVerify = `${prefix}/${parentSlug}`
      const pathToParent = `${prefix}${groupParent}`
      const samePathToAnotherCountrySite = pathToVerify
      const pathExist = allSitePage.includes(`/${pathToVerify}`)
      const verifiedPath = pathExist
        ? samePathToAnotherCountrySite
        : pathToParent

      sessionStorage.clear()
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, null, ' ')
      window.location.pathname = verifiedPath
    }
  }

  return (
    <div className={classes.wrapper}>
      {R.map(
        ({countryCode, localeCode}) => (
          <button
            type="button"
            className={classNames(classes.language)}
            onClick={() => {
              handleChange(countryCode, localeCode)
            }}
          >
            {countryCode.toLocaleUpperCase()}
          </button>
        ),
        filteredCountries,
      )}
    </div>
  )
}

SwitchLanguageSmall.propTypes = {
  currentCountry: string,
}

SwitchLanguageSmall.defaultProps = {
  currentCountry: '',
}

export default SwitchLanguageSmall
