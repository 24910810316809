import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints}) => ({
  '@global': {
    '[type=text], [type=button], [type=reset], button': {
      '-webkit-appearance': 'none !important',
      '-moz-appearance': 'none !important',
      appearance: 'none !important',
    },
    html: {
      scrollbarWidth: 'thin!important',
      scrollbarColor: 'light',
    },
    body: {
      margin: 0,
      color: '#333333 !important',
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'lightgray',
        borderRadius: '10px',
      },
    },
    a: {
      textDecoration: 'none !important',
      '&:hover': {
        color: 'inherit !important',
      },
    },
    gatsbyImageWrapper: {
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
    '.sideWrapper': {
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      '& > div': {
        maxWidth: 1160,
        width: '90%',
      },
    },
    '.decorationWrapper': {
      width: '100%',
      position: 'relative',
    },
  },
  wrapper: {
    fontFamily: 'CamptonMedium',
  },
}))

export default useStyles
