import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({layout, palette, spacing, breakpoints}) => ({
  wrapper: {
    cursor: 'default',
    position: 'absolute',
    width: 40,
    height: 40,
    padding: 0,
    paddingBottom: spacing(1),
    top: spacing(4),
    margin: '0 auto',
    borderTop: `1px solid  ${palette.background.grayDark}`,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [breakpoints.up('sm')]: {
      '& *': {
        color: palette.text.primary,
        textAlign: 'left',
      },
    },
    [breakpoints.down('sm')]: {
      position: 'absolute',
      display: 'block',
      top: layout.header.heightMobile,
    },
  },
  language: {
    background: 'none',
    border: 'none',
    padding: 0,
    textAlign: 'center',
    color: 'black',
    '&:hover': {
      color: palette.primary.main,
    },
  },
}))

export default useStyles
