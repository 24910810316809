import {graphql, useStaticQuery} from 'gatsby'

export const useLayoutData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHeader(
        filter: {
          name: {eq: "Header-kls"}
          node_locale: {in: ["en-US", "fr-FR"]}
        }
      ) {
        edges {
          node {
            node_locale
            redirectionPopperLabel
            redirectionPopperCta1
            redirectionPopperCta2
            headerMenu {
              menuItems {
                isExternalLink
                isOpenInNewWindow
                isSameMenuItemForAllCountries
                link
                name
                title
                menuItemPerCountry {
                  isExternalLink
                  isOpenInNewWindow
                  isSameMenuItemForAllCountries
                  link
                  name
                  title
                  countries {
                    countryCode
                    urlPrefix
                    technicalName
                  }
                }
                childMenu {
                  isSameMenuForAllCountries
                  menuItems {
                    isExternalLink
                    isOpenInNewWindow
                    isSameMenuItemForAllCountries
                    link
                    name
                    title
                  }
                }
              }
            }
            contactUs {
              link
              title
            }
            logo {
              title
              file {
                url
              }
            }
          }
        }
      }
      allContentfulPlaybook {
        edges {
          node {
            searchPlaceholder
            node_locale
          }
        }
      }
      allContentfulFooter(
        filter: {
          name: {eq: "Footer-kls"}
          node_locale: {in: ["en-US", "fr-FR"]}
        }
      ) {
        edges {
          node {
            node_locale
            logo {
              title
              file {
                url
                contentType
              }
            }
            socialMediaLinks {
              name
              link
              id
              icon {
                file {
                  url
                }
              }
              iconHover {
                file {
                  url
                }
              }
            }
            locations {
              faxLabel
              phoneLabel
            }
            firstFooterMenu {
              name
              title
              menuItems {
                name
                menuItemPerCountry {
                  isExternalLink
                  isOpenInNewWindow
                  isSameMenuItemForAllCountries
                  link
                  name
                  title
                  countries {
                    urlPrefix
                    technicalName
                  }
                }
                menu_item {
                  link
                  isExternalLink
                  isOpenInNewWindow
                  isSameMenuItemForAllCountries
                  name
                  title
                }
                isOpenInNewWindow
                isSameMenuItemForAllCountries
                isExternalLink
                link
                id
                title
              }
            }
            secondFooterMenu {
              name
              title
              menuItems {
                name
                menu_item {
                  link
                  isExternalLink
                  isOpenInNewWindow
                  isSameMenuItemForAllCountries
                  name
                  title
                }
                isOpenInNewWindow
                isSameMenuItemForAllCountries
                isExternalLink
                link
                id
                title
              }
            }
            thirdFooterMenu {
              name
              title
              menuItems {
                name
                menu_item {
                  link
                  isExternalLink
                  isOpenInNewWindow
                  isSameMenuItemForAllCountries
                  name
                  title
                }
                isOpenInNewWindow
                isSameMenuItemForAllCountries
                isExternalLink
                link
                id
                title
              }
            }
            bottomMenu {
              menuItems {
                id
                link
                isOpenInNewWindow
                isSameMenuItemForAllCountries
                isExternalLink
                title
                menuItemPerCountry {
                  isExternalLink
                  isOpenInNewWindow
                  isSameMenuItemForAllCountries
                  link
                  name
                  title
                  countries {
                    urlPrefix
                    technicalName
                    countryCode
                  }
                }
              }
            }
          }
        }
      }
      allContentfulLocations(filter: {slug: {regex: "/-kls/"}}) {
        edges {
          node {
            ...locationsFields
          }
        }
      }
      allSitePage {
        distinct(field: {path: SELECT})
      }
    }
  `)

  return data
}
