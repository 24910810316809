module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5K6PST9","includeInDevelopment":false,"timeout":3000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"UbHhL1LGxQeMPH3gWcP9Tw","gtmPreview":"env-17"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["CamptonBook, CamptonSemiBold, CamptonMedium, CamptonLight"],"urls":["static/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
