import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React from 'react'

import {scrollTop} from 'helpers/utils'
import useShowScrollTopButton from 'hooks/useShowScrollTopButton'

import useStyles from './styles'

const ScrollTop = () => {
  const classes = useStyles()
  const [showScrollTopButton] = useShowScrollTopButton()

  return (
    <div>
      {showScrollTopButton && (
        <button
          data-aos="zoom-in"
          onClick={scrollTop}
          type="button"
          className={classes.scrollTop}
        >
          <ArrowUpIcon />
        </button>
      )}
    </div>
  )
}

export default ScrollTop
